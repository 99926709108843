import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 909.000000 771.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,771.000000) scale(0.100000,-0.100000)">
          <path d="M4940 7040 c0 -5 12 -10 28 -10 21 0 24 2 12 10 -20 13 -40 13 -40 0z" />
          <path d="M4270 7020 c0 -5 21 -10 48 -9 38 1 42 2 22 9 -36 12 -70 12 -70 0z" />
          <path d="M4590 7020 c0 -6 29 -10 68 -9 49 1 60 3 42 9 -36 12 -110 12 -110 0z" />
          <path d="M5017 7001 c27 -22 108 -36 155 -26 l33 8 -51 13 c-28 7 -75 15 -105
18 l-54 6 22 -19z"/>
          <path d="M4880 6750 l0 -80 -40 0 -40 0 0 -40 0 -40 -80 0 -80 0 -1 33 c-1 17
-2 48 -1 67 l1 35 -8 -30 c-13 -48 -13 -165 0 -165 8 0 10 -20 6 -68 -3 -38
-2 -73 1 -78 4 -5 104 -11 232 -12 124 -2 249 -6 278 -8 28 -2 52 -3 52 0 0 2
-7 17 -15 32 -8 16 -15 36 -15 44 0 8 -7 24 -15 34 -8 11 -15 27 -15 36 0 9
-9 25 -20 35 -11 10 -20 28 -20 40 0 13 -7 28 -15 35 -8 7 -15 21 -15 31 0 11
-4 19 -9 19 -10 0 -32 35 -52 83 -13 31 -24 34 -38 10 -9 -16 -10 -16 -11 -1
0 9 4 18 10 20 5 2 7 13 4 26 -5 19 -13 22 -50 22 l-44 0 0 -80z m127 -76 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-304 -251 c-13 -2 -33 -2
-45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z"/>
          <path d="M5226 6788 c28 -30 52 -64 79 -107 17 -28 59 -86 65 -91 8 -6 35 -56
50 -92 6 -14 18 -29 26 -32 8 -3 14 -14 14 -25 0 -15 19 -62 38 -91 2 -2 7 2
12 10 7 10 10 11 10 3 0 -10 24 -13 88 -13 48 0 92 -4 98 -10 11 -11 365 -30
377 -20 9 8 -27 50 -155 180 -118 119 -165 160 -255 222 -40 27 -73 53 -73 59
0 5 -33 9 -73 9 -40 0 -77 4 -82 9 -6 5 -64 12 -130 15 l-119 6 30 -32z"/>
          <path d="M5860 6744 c0 -15 14 -30 36 -42 20 -11 64 -45 148 -114 39 -33 121
-114 249 -246 62 -64 73 -72 115 -76 108 -13 292 -48 310 -59 6 -5 12 -4 12 1
0 23 -349 296 -410 320 -14 5 -69 41 -80 52 -3 3 -40 23 -82 45 -43 22 -78 43
-78 47 0 4 -6 8 -13 8 -7 0 -31 9 -52 21 -22 11 -58 24 -80 28 -22 5 -48 12
-57 16 -10 4 -18 3 -18 -1z"/>
          <path d="M4286 6529 c-9 -94 -9 -99 17 -100 12 -1 4 -5 -18 -9 -22 -4 -44 -5
-50 -1 -5 4 -26 4 -45 1 l-35 -6 45 -8 c25 -4 63 -8 85 -9 l39 -2 4 98 c3 90
2 97 -16 97 -16 0 -20 -10 -26 -61z"/>
          <path d="M4236 6083 c-3 -4 -102 -6 -218 -4 -116 1 -239 -1 -274 -4 -63 -7
-106 -26 -91 -41 4 -4 13 -2 19 4 17 17 165 16 170 0 4 -10 10 -10 32 0 32 14
126 16 126 2 0 -5 18 -10 40 -10 l40 0 0 -40 0 -40 -80 0 -80 0 0 -40 0 -40
-80 0 -80 0 0 -40 0 -40 -65 0 c-53 0 -63 3 -59 15 9 23 -6 109 -17 98 -10
-10 -36 -133 -46 -210 -3 -24 -9 -43 -14 -43 -5 0 -9 -11 -9 -25 0 -15 -6 -38
-14 -53 -8 -15 -17 -50 -20 -77 -4 -28 -12 -74 -18 -103 -6 -28 -9 -54 -6 -57
10 -11 732 -48 758 -40 15 5 18 23 23 153 3 81 10 212 17 292 15 184 11 327
-10 340 -18 11 -34 13 -44 3z m-76 -38 c0 -8 -7 -15 -15 -15 -26 0 -18 20 13
29 1 1 2 -6 2 -14z m-27 -82 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m-453 -293 l0 -40 -46 0 c-43 0 -46 2 -40 23 3 12 6 30 6 40 0 13 9 17
40 17 l40 0 0 -40z m-96 -105 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15
4 15 2 0 6 -7 10 -15z"/>
          <path d="M4645 6080 c-3 -5 -1 -19 5 -30 6 -11 10 -48 10 -83 -3 -103 11 -630
16 -656 l5 -24 202 8 c111 4 288 10 392 14 l190 6 -1 42 c0 24 -6 66 -12 95
-12 57 -26 128 -48 247 -7 41 -18 79 -24 85 -5 5 -10 26 -10 46 0 19 -7 48
-15 64 -8 15 -15 36 -15 45 0 9 -9 43 -19 76 l-19 60 -88 6 c-146 11 -562 10
-569 -1z"/>
          <path d="M5616 6072 c-3 -5 1 -19 7 -33 25 -48 57 -147 57 -175 0 -16 7 -38
15 -48 8 -11 15 -27 15 -37 0 -9 6 -39 14 -65 8 -27 24 -85 35 -129 11 -44 27
-107 36 -141 8 -33 15 -71 15 -83 l0 -24 173 8 c94 4 197 11 227 17 30 5 114
13 185 18 72 5 148 15 170 23 43 16 46 26 20 82 -8 17 -26 57 -40 89 -15 32
-35 77 -45 100 -11 23 -29 59 -41 81 -12 22 -29 54 -37 70 -20 40 -100 175
-117 198 -11 16 -38 20 -202 28 -103 5 -228 13 -278 18 -117 12 -203 13 -209
3z"/>
          <path d="M3656 6001 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z" />
          <path d="M6550 6006 c0 -4 21 -39 46 -79 26 -40 54 -83 62 -97 25 -40 155
-301 174 -349 l18 -44 132 7 c73 4 139 11 148 16 8 4 51 11 95 15 120 10 119
9 95 53 -23 43 -43 75 -50 82 -3 3 -19 25 -35 50 -16 25 -32 47 -35 50 -3 3
-23 28 -45 55 -99 124 -119 143 -170 157 -22 6 -44 15 -49 20 -6 4 -25 8 -42
8 -18 0 -45 5 -61 11 -15 7 -68 18 -118 26 -140 23 -165 26 -165 19z"/>
          <path d="M3650 5938 c0 -7 5 -20 10 -28 8 -12 10 -9 10 13 0 15 -4 27 -10 27
-5 0 -10 -6 -10 -12z"/>
          <path d="M3231 5897 c-7 -9 -10 -21 -6 -27 4 -6 2 -17 -4 -24 -9 -10 -7 -15 8
-19 25 -6 34 16 23 57 -7 25 -9 26 -21 13z"/>
          <path d="M3192 5668 c1 -41 1 -41 5 -8 3 19 10 38 16 42 8 5 6 8 -5 8 -15 0
-18 -8 -16 -42z"/>
          <path d="M3150 5591 c-5 -11 -10 -30 -10 -43 0 -13 -11 -52 -23 -88 l-24 -65
-51 -5 c-94 -10 -84 -33 16 -34 l67 -1 6 45 c4 25 16 80 28 124 24 91 24 86
11 86 -5 0 -14 -9 -20 -19z"/>
          <path d="M2648 5409 c-23 -15 1 -21 73 -21 66 1 89 6 78 16 -8 8 -139 12 -151
5z"/>
          <path d="M8115 5391 c-11 -5 -45 -12 -75 -16 -30 -4 -82 -13 -115 -21 -33 -7
-76 -14 -96 -14 -20 0 -59 -6 -85 -14 -51 -15 -151 -32 -264 -45 -36 -5 -110
-15 -165 -24 -55 -9 -152 -23 -215 -31 -63 -9 -146 -20 -185 -26 -38 -6 -119
-15 -180 -20 -60 -5 -126 -14 -145 -20 -19 -6 -96 -15 -170 -20 -74 -5 -214
-16 -310 -24 -326 -28 -445 -36 -625 -41 -99 -3 -205 -11 -235 -18 -81 -17
-1482 -17 -1605 1 -50 7 -210 13 -362 15 -149 1 -269 5 -267 8 6 10 -157 21
-293 21 -71 -1 -123 3 -123 9 0 5 -9 9 -20 9 -13 0 -20 -7 -20 -20 0 -17 7
-20 40 -20 l40 0 0 -91 0 -91 -37 7 c-21 4 -40 11 -41 16 -5 12 -82 12 -82 0
0 -5 -8 -12 -17 -14 -10 -2 -5 -3 11 -1 15 2 42 -2 60 -10 17 -8 54 -17 81
-20 28 -3 84 -14 125 -25 140 -36 175 -43 255 -48 44 -4 89 -12 100 -18 11 -7
47 -14 80 -15 91 -4 106 -6 160 -18 28 -6 102 -13 166 -16 63 -3 128 -10 145
-17 40 -16 483 -33 854 -34 314 0 761 17 885 35 39 5 117 14 175 20 216 22
346 39 380 49 19 6 56 11 83 11 26 0 64 7 84 15 19 8 52 15 72 15 20 0 65 6
101 14 36 8 94 20 130 26 158 30 321 65 340 73 8 3 38 11 65 16 104 21 255 60
305 79 8 3 38 11 65 18 28 7 75 19 105 28 30 8 82 23 115 32 33 9 64 20 69 25
6 5 22 9 37 9 15 0 30 4 34 9 3 5 20 12 38 16 38 8 149 43 194 62 17 7 37 13
45 13 8 0 19 5 25 11 6 6 26 14 44 19 70 18 214 72 230 86 8 8 25 14 37 14 11
0 24 5 27 10 7 12 -41 12 -70 1z m-5368 -317 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m206 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m-276 -45 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m80 -4
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-10 -120 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m73 2 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
          <path d="M2880 5370 c0 -5 18 -10 40 -10 22 0 40 5 40 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10z"/>
          <path d="M2148 5153 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z" />
          <path d="M2400 5120 c0 -12 27 -12 55 0 16 7 13 9 -17 9 -21 1 -38 -3 -38 -9z" />
          <path d="M1950 5050 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M2018 5013 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z" />
          <path d="M2136 4979 c3 -5 39 -9 81 -9 42 0 73 3 70 7 -11 10 -158 13 -151 2z" />
          <path d="M2306 4957 c-18 -13 -14 -15 35 -19 57 -6 69 -3 53 11 -18 17 -69 22
-88 8z"/>
          <path d="M2675 4202 c-22 -2 -54 -9 -72 -17 -17 -8 -37 -15 -45 -15 -20 0
-118 -100 -125 -129 -3 -13 -12 -36 -19 -50 -19 -35 -17 -57 9 -117 26 -60 51
-92 92 -119 44 -29 118 -64 135 -65 8 0 24 -7 34 -15 11 -8 37 -15 57 -15 21
0 44 -6 51 -14 8 -7 23 -16 34 -20 11 -3 28 -21 38 -41 16 -30 17 -38 6 -65
-17 -40 -43 -57 -105 -65 -78 -10 -134 7 -172 52 -18 21 -33 47 -33 58 -1 40
-13 46 -86 43 l-69 -3 -3 -71 -3 -70 58 -62 c31 -34 63 -62 70 -62 8 0 24 -6
36 -14 40 -25 167 -38 243 -26 86 14 137 40 191 97 33 35 43 55 48 94 9 64 -3
165 -21 180 -8 6 -14 17 -14 24 0 6 -11 18 -25 25 -14 7 -25 17 -25 22 0 4 -6
8 -13 8 -8 0 -22 9 -32 20 -10 11 -24 20 -30 20 -7 0 -26 7 -41 15 -16 8 -40
15 -54 15 -14 0 -34 7 -44 15 -11 8 -32 15 -47 15 -42 0 -112 69 -107 106 5
44 77 94 134 94 71 0 116 -32 140 -99 l20 -56 77 -3 c86 -3 81 -9 78 81 -1 32
-10 59 -27 85 -36 53 -103 110 -139 118 -16 3 -34 10 -40 14 -12 9 -103 16
-160 12z"/>
          <path d="M6735 4203 c-22 -2 -49 -8 -60 -12 -83 -37 -115 -54 -115 -61 0 -5
-9 -12 -20 -15 -11 -3 -20 -12 -20 -19 0 -7 -9 -25 -20 -39 -30 -38 -29 -175
2 -226 12 -20 27 -41 33 -46 28 -27 149 -94 171 -95 7 0 25 -7 40 -15 16 -8
41 -15 55 -15 14 0 34 -7 45 -15 10 -8 26 -15 34 -15 19 0 60 -60 60 -88 0
-34 -41 -72 -89 -83 -108 -24 -211 19 -213 89 -1 44 -29 62 -96 62 -76 0 -92
-11 -92 -65 0 -57 30 -115 84 -161 86 -75 203 -103 339 -82 45 6 88 18 95 25
7 7 19 13 26 13 21 0 86 71 106 117 24 54 26 127 5 167 -8 15 -15 34 -15 40 0
36 -180 155 -235 156 -11 1 -31 7 -45 15 -14 8 -37 14 -52 15 -35 0 -98 59
-98 91 0 46 12 66 50 88 48 27 108 27 154 0 42 -24 76 -76 76 -113 0 -26 1
-26 73 -26 62 0 77 3 92 21 17 18 17 24 6 52 -7 18 -16 43 -19 57 -6 28 -37
75 -67 103 -58 53 -187 89 -290 80z"/>
          <path d="M265 4170 c-6 -20 27 -101 53 -128 6 -7 12 -20 12 -27 0 -7 6 -20 13
-27 6 -7 27 -42 46 -78 18 -36 60 -116 92 -178 l59 -113 0 -154 0 -155 84 0
84 0 4 157 c2 114 6 157 16 161 6 2 12 12 12 22 0 10 4 21 9 25 6 3 31 47 57
97 25 51 57 109 71 131 13 21 28 48 33 60 6 12 14 29 19 37 5 8 12 22 16 30 3
8 19 39 35 69 22 43 26 59 18 74 -9 17 -19 19 -89 15 -91 -5 -92 -6 -141 -108
-17 -36 -34 -67 -38 -70 -4 -3 -10 -17 -14 -32 -3 -16 -11 -28 -16 -28 -6 0
-10 -8 -10 -18 0 -9 -12 -34 -26 -55 -25 -37 -26 -38 -46 -21 -10 10 -42 63
-69 119 -27 55 -55 109 -62 120 -6 10 -22 36 -35 57 l-22 38 -79 0 c-71 0 -80
-2 -86 -20z"/>
          <path d="M1202 4172 c-11 -10 -25 -36 -31 -59 -6 -23 -16 -45 -21 -48 -6 -3
-10 -19 -10 -34 0 -16 -7 -34 -15 -41 -8 -7 -15 -20 -15 -29 -1 -9 -9 -34 -20
-56 -11 -22 -19 -47 -20 -56 0 -9 -7 -22 -15 -29 -8 -7 -15 -25 -15 -41 0 -15
-4 -30 -9 -34 -5 -3 -13 -20 -17 -38 -3 -17 -13 -45 -20 -62 -21 -48 -82 -208
-89 -232 -3 -13 -11 -23 -16 -23 -5 0 -9 -18 -9 -40 l0 -41 87 3 86 3 19 55
c11 30 22 66 25 80 3 14 10 30 14 36 11 14 243 27 283 16 21 -6 32 -17 37 -36
4 -15 12 -33 17 -39 6 -7 13 -29 17 -49 13 -69 24 -71 353 -69 l62 1 0 175 0
175 29 0 c19 0 34 -8 47 -26 36 -49 99 -129 110 -139 6 -5 41 -49 78 -97 l67
-88 88 0 c128 0 128 18 -2 173 -29 34 -66 80 -82 102 -17 22 -32 42 -35 45 -3
3 -22 25 -42 50 -67 85 -70 73 45 215 203 254 203 255 175 283 -15 15 -119 16
-150 1 -13 -6 -39 -31 -58 -56 -19 -25 -43 -54 -52 -64 -10 -11 -18 -21 -18
-24 0 -3 -8 -13 -17 -23 -25 -25 -52 -59 -93 -114 -35 -48 -61 -65 -80 -53 -6
4 -10 69 -10 164 0 128 -3 161 -15 171 -16 13 -148 11 -162 -4 -4 -4 -10 -193
-13 -419 -4 -352 -7 -412 -20 -412 -8 0 -17 14 -21 33 -4 19 -12 39 -18 45 -6
6 -11 20 -11 32 0 11 -6 29 -14 40 -7 11 -17 35 -21 53 -4 18 -13 45 -21 60
-8 15 -14 34 -14 43 0 9 -7 22 -15 29 -8 7 -15 24 -15 39 0 14 -7 35 -15 45
-8 11 -15 30 -15 42 0 13 -4 25 -9 29 -5 3 -17 30 -25 60 -9 30 -21 57 -26 60
-6 3 -10 14 -10 24 0 10 -7 32 -15 47 -8 16 -15 39 -15 50 0 12 -7 27 -15 34
-8 7 -15 23 -15 36 0 17 -8 28 -26 34 -41 16 -109 12 -132 -8z m101 -332 c5
-25 12 -47 16 -50 3 -3 11 -24 17 -47 14 -56 3 -65 -73 -61 -57 3 -58 3 -61
35 -2 18 4 43 12 55 9 12 16 34 16 48 0 31 29 71 50 67 8 -1 18 -21 23 -47z"/>
          <path d="M3428 4179 c-17 -9 -18 -41 -18 -439 l0 -430 138 -1 c210 -2 271 2
300 17 15 8 32 14 39 14 29 0 124 104 158 174 l35 72 0 158 c0 160 0 163 -44
256 -16 36 -116 133 -156 152 -19 9 -70 21 -113 27 -98 14 -316 14 -339 0z
m396 -186 c61 -49 71 -82 71 -253 l0 -156 -33 -41 c-50 -63 -101 -85 -192 -81
l-75 3 -3 267 c-2 211 0 269 11 280 10 10 36 12 103 8 73 -4 95 -9 118 -27z"/>
          <path d="M4392 4178 c-13 -18 -51 -105 -90 -208 -11 -30 -36 -97 -56 -147 -20
-51 -36 -97 -36 -103 0 -5 -4 -10 -9 -10 -5 0 -12 -17 -16 -37 -4 -20 -13 -46
-21 -57 -8 -11 -14 -30 -14 -41 0 -11 -7 -29 -15 -39 -8 -11 -15 -28 -15 -38
0 -10 -9 -35 -20 -55 -11 -21 -20 -46 -20 -55 0 -10 -4 -18 -10 -18 -5 0 -10
-13 -10 -30 l0 -30 90 0 c81 0 90 2 90 19 0 10 7 24 15 31 8 7 15 22 15 34 0
11 9 40 19 63 l20 42 137 3 c153 3 160 1 184 -66 6 -17 18 -46 26 -64 8 -18
14 -39 14 -47 0 -12 18 -15 90 -15 l90 0 0 28 c0 15 -7 36 -15 46 -8 11 -15
30 -15 42 0 12 -6 27 -14 33 -7 6 -16 29 -20 51 -3 22 -11 40 -16 40 -6 0 -10
11 -10 25 0 14 -9 42 -20 62 -11 21 -20 46 -20 55 0 10 -4 18 -9 18 -5 0 -12
17 -16 37 -4 20 -13 46 -21 57 -8 11 -14 30 -14 41 0 11 -7 29 -15 39 -8 11
-15 29 -15 41 0 12 -7 30 -15 41 -8 10 -15 26 -15 34 0 8 -9 33 -20 55 -11 22
-19 48 -20 58 0 9 -12 31 -27 47 -23 26 -33 30 -78 30 -30 0 -57 -5 -63 -12z
m98 -332 c11 -25 20 -54 20 -64 0 -11 7 -32 15 -48 21 -40 5 -54 -64 -54 -71
0 -91 11 -73 40 6 11 12 31 12 45 0 14 9 42 20 62 11 21 20 43 20 50 0 30 31
10 50 -31z"/>
          <path d="M4823 4179 c-20 -7 -23 -15 -23 -66 0 -32 4 -65 10 -73 7 -11 38 -16
127 -20 l118 -5 3 -352 2 -353 90 0 89 0 3 349 c2 192 6 352 9 355 3 3 37 6
75 6 38 1 93 4 122 7 l53 5 -3 77 -3 76 -325 2 c-179 1 -335 -3 -347 -8z"/>
          <path d="M5723 4182 c-14 -9 -53 -106 -53 -132 0 -11 -4 -20 -9 -20 -5 0 -13
-15 -16 -32 -4 -18 -14 -42 -21 -53 -8 -11 -14 -26 -14 -33 0 -8 -6 -28 -14
-45 -8 -18 -31 -75 -51 -127 -20 -52 -43 -109 -51 -127 -8 -17 -14 -35 -14
-40 0 -11 -46 -122 -54 -130 -3 -3 -6 -13 -6 -22 -1 -9 -9 -34 -20 -56 -11
-22 -19 -43 -20 -47 0 -5 42 -8 93 -8 l92 1 18 38 c9 20 17 42 17 48 0 6 9 31
20 57 l21 46 143 0 c161 0 161 0 183 -74 6 -23 17 -58 25 -78 l13 -38 87 0 88
0 0 28 c0 16 -7 37 -15 48 -8 10 -15 28 -15 40 0 11 -7 29 -15 40 -8 10 -15
27 -15 37 0 9 -9 34 -20 54 -11 21 -20 46 -20 55 0 10 -4 18 -9 18 -5 0 -12
17 -15 38 -4 20 -13 51 -20 67 -8 17 -22 50 -31 75 -9 25 -23 59 -30 75 -30
73 -55 142 -55 155 0 8 -7 24 -15 34 -8 11 -15 27 -15 35 0 8 -10 30 -22 48
-21 31 -27 33 -78 33 -30 0 -60 -4 -67 -8z m89 -324 c9 -18 20 -48 23 -66 4
-18 13 -45 21 -60 20 -38 3 -52 -65 -52 -73 0 -89 10 -73 45 7 14 12 36 12 49
0 12 7 31 15 42 8 10 15 28 15 40 0 45 29 46 52 2z"/>
          <path d="M7188 4171 c-16 -16 -18 -43 -18 -276 0 -339 13 -424 77 -495 79 -88
194 -120 349 -99 46 7 84 15 84 19 0 5 11 10 24 14 12 3 48 31 79 62 41 42 56
65 60 92 3 20 10 55 16 77 7 25 11 143 10 310 -1 325 3 315 -97 315 -40 0 -73
-5 -80 -12 -9 -9 -12 -95 -12 -323 l0 -310 -33 -38 c-36 -44 -98 -62 -171 -51
-57 9 -73 19 -101 64 l-25 40 0 299 c0 165 -3 306 -6 315 -9 24 -132 21 -156
-3z"/>
          <path d="M7976 4174 c-3 -9 -6 -207 -6 -440 0 -332 3 -424 13 -424 6 -1 107 0
223 0 189 1 215 3 250 20 72 37 106 78 135 162 20 61 19 130 -2 144 -5 3 -9
13 -9 22 0 10 -16 41 -34 70 l-35 52 27 52 c60 116 18 258 -93 320 -40 22
-182 38 -336 38 -107 0 -128 -3 -133 -16z m392 -177 c31 -29 31 -95 -2 -126
-21 -20 -32 -22 -122 -19 l-99 3 -3 76 c-3 70 -1 76 19 85 12 6 59 8 103 6 64
-3 86 -8 104 -25z m-10 -321 c47 -25 62 -52 62 -109 0 -45 -4 -58 -26 -78 -22
-21 -37 -24 -115 -28 -49 -1 -101 0 -114 3 -24 6 -25 9 -25 96 0 125 4 130
108 130 52 0 93 -5 110 -14z"/>
          <path d="M4164 2549 c-164 -4 -328 -11 -364 -18 -36 -6 -101 -11 -145 -11 -44
0 -107 -7 -140 -15 -33 -8 -90 -15 -126 -15 -36 0 -90 -5 -120 -10 -30 -6
-103 -17 -164 -25 -150 -20 -283 -43 -470 -81 -93 -18 -166 -32 -245 -45 -36
-6 -77 -17 -92 -25 -15 -8 -39 -14 -54 -14 -15 0 -41 -4 -58 -10 -17 -5 -85
-23 -151 -39 -171 -43 -206 -52 -242 -67 -17 -8 -44 -14 -60 -14 -15 0 -37 -7
-47 -15 -11 -8 -32 -15 -48 -15 -15 0 -42 -7 -60 -14 -18 -8 -67 -25 -108 -36
-41 -12 -89 -28 -107 -36 -17 -8 -43 -14 -57 -14 -14 0 -26 -4 -26 -10 0 -5
-17 -12 -38 -15 -20 -3 -50 -12 -65 -20 -16 -8 -37 -15 -48 -15 -16 0 -61 -19
-121 -51 -10 -5 -28 -9 -40 -9 -13 0 -38 -9 -56 -20 -18 -11 -42 -20 -53 -20
-11 0 -18 -4 -15 -9 3 -4 29 -4 58 1 52 9 171 27 318 48 41 6 90 16 108 21 18
6 66 15 105 19 40 5 133 18 207 30 74 12 191 25 260 29 69 5 141 14 160 20 19
7 82 16 140 21 58 5 197 19 310 30 113 12 275 25 360 30 85 5 180 14 210 19
30 5 100 10 155 10 55 1 190 8 300 16 234 17 1589 20 1765 5 58 -6 206 -14
330 -19 124 -6 239 -15 255 -20 17 -5 91 -12 165 -14 74 -3 171 -10 215 -16
75 -10 300 -33 510 -51 50 -5 106 -13 125 -19 19 -6 60 -11 90 -11 30 0 91 -6
135 -14 44 -9 117 -20 163 -26 45 -5 119 -15 165 -21 45 -5 110 -17 144 -25
34 -7 81 -14 106 -14 24 0 61 -7 80 -15 20 -8 52 -15 71 -15 19 0 63 -6 98
-14 105 -24 198 -31 198 -15 0 8 -22 21 -48 30 -81 28 -196 69 -217 79 -21 9
-54 20 -285 94 -125 41 -200 63 -340 102 -30 9 -68 20 -85 26 -56 18 -130 37
-240 63 -60 14 -130 32 -155 40 -25 8 -56 15 -70 15 -14 1 -36 7 -50 15 -14 8
-41 14 -60 15 -19 0 -56 6 -81 14 -25 8 -80 17 -122 21 -42 4 -77 11 -77 15 0
5 -26 11 -57 15 -32 3 -74 11 -93 17 -19 6 -66 15 -105 19 -38 4 -92 12 -120
17 -27 6 -111 17 -185 26 -74 9 -175 21 -225 26 -202 24 -451 42 -640 46 -110
2 -252 5 -316 7 -63 1 -250 -1 -415 -4z"/>
          <path d="M3660 1933 c-25 -1 -69 -6 -99 -9 l-53 -6 6 -36 c5 -36 17 -95 50
-250 9 -40 20 -81 26 -92 5 -10 10 -34 10 -52 0 -19 4 -38 9 -44 5 -5 21 -52
35 -104 15 -52 34 -111 43 -131 l15 -36 121 -7 c347 -17 472 -18 491 -3 18 13
19 21 8 163 -6 82 -14 248 -18 369 -3 126 -10 225 -16 233 -8 9 -78 12 -297
10 -157 -1 -306 -3 -331 -5z"/>
          <path d="M4667 1933 c-4 -3 -7 -31 -7 -62 0 -31 -5 -85 -10 -121 -5 -36 -10
-130 -11 -210 -1 -80 -6 -194 -11 -254 -8 -91 -7 -112 5 -124 13 -12 57 -13
310 -5 l295 8 21 25 c11 14 20 35 21 47 0 12 6 37 14 55 8 18 22 58 30 88 8
30 24 87 35 127 12 39 21 79 21 89 0 10 6 36 14 59 23 67 38 147 40 210 l1 60
-65 6 c-88 9 -695 11 -703 2z"/>
          <path d="M5766 1894 c-15 -14 -36 -75 -36 -104 0 -8 -6 -32 -14 -55 -7 -22
-21 -67 -31 -100 -28 -101 -90 -273 -123 -345 -12 -25 -23 -61 -24 -80 l-3
-35 49 -3 c27 -2 104 4 171 12 66 9 163 16 213 16 51 0 123 7 160 15 l67 16
55 82 c78 115 116 179 167 277 24 47 48 92 52 100 25 42 61 124 61 135 0 18
-61 28 -267 44 -68 6 -158 14 -200 20 -43 6 -124 12 -180 15 -82 4 -105 2
-117 -10z"/>
          <path d="M2975 1890 c-38 -4 -115 -10 -170 -14 -55 -4 -145 -13 -200 -21 -55
-8 -116 -16 -135 -17 -45 -4 -44 -20 13 -136 62 -127 203 -371 227 -392 3 -3
17 -21 31 -40 34 -46 95 -60 299 -71 85 -5 206 -11 268 -15 109 -6 112 -6 112
14 0 12 -11 45 -24 74 -13 29 -34 80 -46 113 -13 33 -29 74 -36 92 -8 17 -14
40 -14 51 0 12 -4 23 -8 26 -5 3 -21 54 -37 113 -15 59 -34 120 -42 135 -7 15
-13 40 -13 56 0 18 -6 32 -16 36 -19 7 -113 5 -209 -4z"/>
          <path d="M8165 1830 c3 -5 22 -10 41 -10 21 0 33 4 29 10 -3 6 -22 10 -41 10
-21 0 -33 -4 -29 -10z"/>
          <path d="M2085 1795 c-33 -7 -105 -16 -160 -20 -55 -4 -115 -13 -133 -21 l-33
-14 35 -47 c61 -82 88 -115 178 -214 67 -74 100 -102 145 -124 32 -15 66 -32
77 -37 10 -5 39 -13 65 -18 25 -4 92 -16 148 -26 129 -23 143 -15 90 50 -21
24 -37 49 -37 54 0 6 -4 12 -8 14 -11 4 -118 162 -158 233 -6 11 -21 36 -32
55 -12 19 -29 52 -38 73 -16 37 -40 58 -66 56 -7 0 -40 -6 -73 -14z"/>
          <path d="M6728 1768 c-14 -24 -40 -72 -59 -108 -19 -36 -37 -69 -41 -75 -4 -5
-40 -59 -80 -120 -39 -60 -74 -112 -77 -115 -14 -12 -51 -70 -51 -80 0 -19 50
-16 195 11 109 20 148 32 235 72 53 24 75 45 205 197 54 63 108 127 120 141
30 35 40 68 23 74 -16 6 -288 36 -379 42 l-66 5 -25 -44z"/>
          <path d="M6518 1084 c-8 -7 -147 -34 -228 -43 -69 -8 -101 -21 -122 -49 -19
-24 -211 -191 -263 -227 -124 -87 -167 -115 -173 -115 -4 -1 -23 -14 -42 -30
l-34 -30 31 0 c18 0 35 5 38 10 3 6 16 10 27 10 11 0 39 9 62 19 182 85 216
102 221 111 3 6 15 10 26 10 10 0 19 5 19 10 0 6 5 10 10 10 10 0 204 110 225
128 6 4 17 11 25 15 21 10 52 30 68 45 7 6 35 26 60 43 46 31 92 73 92 84 0 7
-35 7 -42 -1z"/>
          <path d="M2437 1045 c47 -42 215 -156 294 -200 30 -16 88 -49 129 -72 70 -40
190 -100 286 -144 23 -11 51 -19 62 -19 11 0 24 -5 27 -10 3 -5 16 -10 27 -10
21 0 20 2 -7 30 -16 17 -35 30 -42 30 -7 0 -13 5 -13 10 0 6 -6 10 -12 10 -10
0 -116 72 -173 117 -6 4 -33 26 -60 47 -48 38 -115 98 -159 142 -39 38 -70 52
-139 58 -37 4 -94 13 -125 20 -31 8 -75 17 -97 21 l-40 6 42 -36z"/>
          <path d="M2980 980 c0 -20 208 -219 269 -258 14 -9 34 -24 43 -32 9 -8 61 -42
115 -74 90 -55 105 -61 193 -73 141 -19 236 -27 255 -19 14 5 11 12 -21 44
-61 61 -104 111 -161 192 -29 41 -58 80 -63 87 -6 7 -10 15 -10 18 0 3 -12 23
-26 45 -32 48 -25 47 -304 60 -118 6 -232 13 -252 16 -28 5 -38 3 -38 -6z"/>
          <path d="M5905 985 c-5 -2 -111 -8 -235 -15 -124 -6 -234 -13 -246 -15 -12 -3
-39 -34 -69 -82 -73 -115 -145 -211 -209 -276 -31 -31 -54 -62 -51 -67 9 -15
102 -12 192 5 43 8 93 15 111 15 50 0 175 69 289 159 29 22 54 41 56 41 2 0
24 19 49 43 25 23 71 65 102 92 31 28 64 62 72 78 14 27 14 27 -18 26 -18 0
-37 -2 -43 -4z"/>
          <path d="M3805 930 c-14 -23 151 -313 221 -389 21 -23 34 -27 112 -33 147 -11
230 -9 235 5 6 18 -2 190 -14 282 -5 44 -11 90 -13 103 -1 13 -9 28 -17 33
-20 13 -516 12 -524 -1z"/>
          <path d="M4623 930 c-19 -8 -22 -19 -27 -107 -4 -54 -11 -142 -17 -195 -6 -54
-8 -105 -5 -114 6 -15 22 -16 158 -11 111 5 158 11 175 23 27 17 108 124 138
181 11 21 22 40 25 43 8 8 50 94 50 103 0 5 7 15 16 24 8 9 14 25 12 37 -3 21
-6 21 -253 23 -137 1 -260 -2 -272 -7z"/>
          <path d="M3917 414 c-15 -15 7 -24 58 -24 54 0 79 12 49 24 -21 8 -99 8 -107
0z"/>
          <path d="M4923 414 c-33 -14 -7 -25 55 -22 38 2 66 7 65 13 -3 11 -99 18 -120
9z"/>
          <path d="M4250 386 c-9 -12 -6 -16 23 -21 58 -11 106 -10 119 3 20 20 -8 32
-73 32 -37 0 -61 -5 -69 -14z"/>
          <path d="M4571 386 c-19 -22 10 -32 71 -26 40 4 54 9 56 23 3 15 -5 17 -56 17
-39 0 -64 -5 -71 -14z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
